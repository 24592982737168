import React, { FunctionComponent } from "react";
import { useStaticQuery, graphql } from "gatsby";
import { Link } from "@material-ui/core";
import { TwitterImage } from "./TwitterImage";

type Property = {
  path: string;
  title: string;
}

export const TwitterShareButton: FunctionComponent<Property> = (props) =>{
  
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            home
          }
        }
      }
    `
  );
  const home = data.site.siteMetadata.home;
  const shareUrl = `${home}${props.path}`;

  const twitterUrl = `https://twitter.com/share?url=${encodeURI(shareUrl)}&text=${encodeURI(`${props.title} | hassakulab`)}`;

  return (
    <Link href={twitterUrl} target="_blank" >
      <TwitterImage />
    </Link>
  )
}