import React from "react";
import { graphql } from "gatsby";
import { Seo } from "../components/helmets/Seo";
import { HeadlineBlock } from "../components/articles/HeadlineBlock";
import { Box, Typography } from "@material-ui/core";
import { AstRenderer } from "../components/articles/AstRenderer";
import "../styles/article.css";
import { ShareBelt } from "../components/social/ShareBelt";
import { BreadNav } from "../components/common/BreadNav";
import { Layout } from "./Layout";
import styled from "styled-components";

const StyledArticleBox = styled.article`
  max-width: 960px;
`;

const Content = ({data})=>{
  const markdownRemark = data.markdownRemark;

  return(
    <Layout >
      <Seo
        title={markdownRemark.frontmatter.title}
        article
        description={markdownRemark.excerpt}
        image={markdownRemark.frontmatter.cover?.childImageSharp?.fluid.src}
      />
      <Box my={2}>
        <BreadNav currentPage={""} links={[{label: "Home", link: "/"}, {label: "Contents", link: "/contents"}]} />
      </Box>
      <StyledArticleBox>
        <HeadlineBlock title={markdownRemark.frontmatter.title} my={0}>
          <Box mt={1} mb={2}>
            <Typography variant="body2" component="span" color="textSecondary">
              {markdownRemark.frontmatter.date}
            </Typography>
          </Box>
        </HeadlineBlock>
        <Box my={2}>
          <AstRenderer htmlAst={markdownRemark.htmlAst} />
        </Box>
        <Box my={4}>
          <ShareBelt
            title={markdownRemark.frontmatter.title}
            description={markdownRemark.frontmatter.description}
            path={`/contents${markdownRemark.fields.slug}`}
          />
        </Box>
      </StyledArticleBox>
    </Layout>
  );
}


export const pageQuery = graphql`
  query ContentBySlug($slug: String) {
    markdownRemark(
      fileAbsolutePath: {regex: "/\/contents\//"},
      fields: { slug: { eq: $slug } }
    ){
      frontmatter {
        date(formatString: "YYYY/MM/DD")
        title
        cover{
          childImageSharp {
            fluid(maxWidth: 600) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
      excerpt(pruneLength: 160)
      htmlAst
      fields {
        slug
      }
    }
  }
`;

export default Content;