import React, { FunctionComponent } from "react";
import { Breadcrumbs, Link, Typography } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";

type LabeledLink={
  label: string;
  link: string;
}

type Property={
  links: LabeledLink[];
  currentPage: string;
}

export const BreadNav: FunctionComponent<Property> = (props) =>(
  <Breadcrumbs separator={<NavigateNextIcon />} aria-label="breadcrumb">
    {props.links.map(ll => (
      <Link color="textSecondary" href={ll.link} key={ll.link}>{ll.label}</Link>
    ))}
    <Typography color="textPrimary" component="span">{props.currentPage}</Typography>
  </Breadcrumbs>
)