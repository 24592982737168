import React, { FunctionComponent } from "react";
import { Grid } from "@material-ui/core";
import { TwitterShareButton } from "./TwitterShareButton";

type Property = {
  title: string;
  description: string;
  path: string;
}

export const ShareBelt: FunctionComponent<Property> = (props) =>{

  return (
    <Grid container justify="flex-end" alignItems="center" spacing={2}>
      <Grid item>
        <TwitterShareButton title={props.title} path={props.path} />
      </Grid>
    </Grid>
  )
}